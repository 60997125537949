// just some javascript for form controls on the reach edit form
export const ReachForm = {
  init() {
    const $gaugeMetricField = $('.form-group.reach_gauge_metric');
    // if no gauge is selected, initialize with gauge metric field hidden
    if (!$("#reach_gauge_id").val()) {
      $gaugeMetricField.hide();
    }
    $("#reach_gauge_id").on('change', (e) => {
      if ($(e.target).val()) {
        $gaugeMetricField.show();
      } else {
        $gaugeMetricField.hide();
      }
    })
  }
}