import { ReachMap } from './ReachMap.js';
import { ReachPoi } from './ReachPoi.js';

// ReachMapEditor does not do any of the creating, updating, or deleting. Instead,
// it creates and maintains references to hidden input fields for each ReachPoi
// in the parent form, which are submitted synchronously with the form submission
// and accepted as nested attributes.
//
// for existing reaches with existing POIs (i.e. editing, not creating),
// it loads the reaches with an ajax call to /reach_pois?reach_id=reachId
// then adds them to the map as markers
//
// then, instead of using popups, we have a form container below the map.
// Every marker has a hidden set of form fields in that container that are bound
// to the reachPoi object. When you click the marker, those fields are displayed
// and when the form is submitted, the entire set of fields is included
//
// there are a lot of other ways we could approach this, but until this becomes
// a problem, we're going this route.
export const ReachMapEditor = {
  mountId: 'reach_map_editor',
  async init() {
    const mountElement = document.getElementById(this.mountId);
    if (mountElement) {
      this.reachId = mountElement.dataset.reachId;

      this.map = new ReachMap({
        container: this.mountId,
        isEditor: true,
        allowFullscreen: false
      });

      $("#add_reach_poi").on('click', () => { this.createNewReachPoi() });

      this.templateHtml = document.getElementById('reach_poi_form_template').innerHTML;
    }
  },

  parseTemplateHtml(id) {
    return this.templateHtml
      .replace(/reach_poi_'/g, `reach_poi_${id}'`)
      .replace(/reach_poi_"/g, `reach_poi_${id}"`)
      .replace(/attributes\]\[\d+\]\[/g, `attributes][${id}][`)
      .replace(/attributes_\d+_/g, `attributes_${id}_`);
  },

  createNewReachPoi() {
    const newId = (new Date).getTime();

    // get template form
    const newReachPoiForm = this.parseTemplateHtml(newId);
    $("#reach_map_editor_container").append(newReachPoiForm);
    const formFieldContainer = $(`#reach_poi_${newId}`);

    const newPoi = new ReachPoi({
      id: newId,
      editable: true,
      lonlat: this.map.getCenter(),
      map: this.map,
      formFieldContainer,
      openPopup: true
    });
    this.map.reachPois.push(newPoi);

    $(formFieldContainer).collapse('toggle');
  }

}
