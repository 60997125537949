import mapboxgl from 'mapbox-gl';

export class ReachPoi {
  constructor(args) {
    this.pointType = args.pointType || 'other';
    this.description = args.description || 'description';
    this.name = args.name || 'A point of interest';
    this.id = args.id;
    this.lonlat = args.lonlat;
    this.image = args.image;
    this.map = args.map;

    // editable defaults to false
    this.editable = args.editable || false;
    // openPopup flag is passed when creating a new marker
    this.openPopup = args.openPopup || false;

    if (args.map) {
      this.addMarkerToMap(args.map);
    }

    if (this.editable) {
      // set if ReachPoi needs to be bound to form items
      this.formFieldContainer = args.formFieldContainer;
      this.bindToFormFields();
    }
  }

  createReachPoiPopup() {
    const popup = new mapboxgl.Popup;
    popup.setHTML(this.render());
    return popup;
  }

  addMarkerToMap(map) {
    this.popup = this.createReachPoiPopup();
    // we're storing a reference to the marker on each poi object
    const markerOpts = {
      color: this.pointTypeColor()
    };
    if (this.editable) {
      markerOpts.draggable = true;
    }
    this.marker = new mapboxgl.Marker(markerOpts)
      .setLngLat(this.lonlat)
      .setPopup(this.popup)
      .addTo(map);
    if (this.openPopup) {
      this.marker.togglePopup();
    }
  }

  // this is pretty funky and depends on the markup in the Rails views
  // which isn't my favorite, but it seemed like the simplest approach (for now)
  bindToFormFields() {
    // map control to form binding
    // initialize lnglat field to current location
    const initialLngLat = this.marker.getLngLat();
    this.findFormField('lonlat').val(`POINT(${initialLngLat.lng} ${initialLngLat.lat})`);
    // bind marker drag option to lnglat field
    this.marker.on('dragend', (event) => {
      const lngLat = event.target.getLngLat();
      this.findFormField('lonlat').val(`POINT(${lngLat.lng} ${lngLat.lat})`);
    });

    // form control to map binding
    ['name', 'point_type', 'description'].forEach((attr) => {
      // update the object
      this.findFormField(attr).on('change', (event) => {
        this[snakeToCamel(attr)] = $(event.target).val();
        // re-render the popup
        if (this.popup) {
          this.popup.setHTML(this.render());
        }

        if (attr === 'point_type') {
          // re-render the marker if the point_type has changed
          this.marker.remove();
          this.addMarkerToMap(this.map);
        }

      });
    });
  }

  findFormField(fieldName) {
    return $(this.formFieldContainer).find(`[name*='${fieldName}']`);
  }

  render() {
    const editButton = `
      <div class="row">
        <div class="col">
          <span role="button" class="btn btn-block btn-primary btn-sm"
            data-target="#reach_poi_${this.id}" data-toggle="collapse"
          >
            Edit
            <span class="fe fe-edit"></span>
          </span>
        </div>
      </div>
    `;

    var imgThumb = '';

    if (this.image) {
      imgThumb = `
        <div class="row">
          <img src="${this.image}" class="img-fluid" />
        </div>
      `;
    }

    return `
      <div class="row">
        <h4 class="col-auto">
          ${this.name}
        </h4>
        <div class="col-auto">
          <span class="badge badge-${this.pointTypeClass()}">${this.pointType}</span>
        </div>
      </div>
      <div class="row">
        <p class="col text-muted">${this.description}</p>
      </div>
      ${ this.editable ? editButton : imgThumb }
    `;
  }

  pointTypeClass() {
    return({
      'putin': 'success',
      'takeout': 'primary',
      'rapid': 'info',
      'portage': 'danger',
      'other': 'secondary',
      'parking': 'secondary',
      'danger': 'danger'
    }[this.pointType]);
  }

  // duplicates the bootstrap class colors for the map marker
  pointTypeColor() {
    return({
      'putin': '#28a745',
      'takeout': '#007bff',
      'rapid': '#17a2b8',
      'portage': '#ffc107',
      'other': '#6c757d',
      'parking': '#6c757d',
      'danger': '#dc3545'
    }[this.pointType]);
  }
}

const snakeToCamel = str => str.replace(/([-_]\w)/g, g => g[1].toUpperCase());
