// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;

require("bootstrap");
require('datatables.net-bs4');
require('select2');
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("trix")
require("@rails/actiontext")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


import { ReachMapEditor } from 'components/reach-map-editor';
import { ReachIndex } from 'components/reach-index';
import { ReachMap } from 'components/ReachMap';
import { ReachForm } from 'components/reach-form';

// this is initialized here so that it can be set on reach pages
// by the HAML view (serverside rendering)
var reachPois;

document.addEventListener("turbolinks:load", () => {
  $('.select2').select2({
    theme: 'bootstrap4',
    allowClear: $(this).data('allowClear'),
    placeholder: $(this).data('placeholder')
  });

  $('[data-toggle="tooltip"]').tooltip();

  if (document.getElementsByClassName('reach-form')) {
    ReachForm.init();
  }

  if (document.getElementById('reach_map_editor')) {
    ReachMapEditor.init();
  }
  if (document.getElementById('reach_map')) {
    new ReachMap({
      container: 'reach_map',
      isEditor: false
    });
  }
  if (document.getElementById('reach_index')) {
    ReachIndex.init();
  }
});
