// initializes Datatables on the reach index table
export const ReachIndex = {
  mountId: 'reach_index',
  init() {
    this.table = $(`#${this.mountId}`).DataTable({
      paging: false,
      order: [[2, 'asc']],
      dom: 't'
    });

    this.regionFilter = $("#region_filter");

    this.regionFilter.on('change', (event) => {
      const selectedRegion = $(event.target).val();
      localStorage.setItem('selectedRegion', selectedRegion);
      this.table.column(2)
        .search(selectedRegion)
        .draw();
    });

    if (localStorage.selectedRegion && localStorage.selectedRegion.length) {
      this.regionFilter.val(localStorage.selectedRegion).trigger( "change" );
    }

    this.searchFilter = $("#search_filter");

    this.searchFilter.on('keyup', (event) => {
      this.table
        .search($(event.target).val())
        .draw();
    });
  }
}
