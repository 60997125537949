import { BCWWMap } from './BCWWMap.js';
import mapboxgl from 'mapbox-gl';
import { ReachPoi } from './ReachPoi.js';

export class ReachMap extends BCWWMap {
  constructor(args) {
    super(args);

    this.reachPois = [];
    this.mapBounds = new mapboxgl.LngLatBounds();

    this.loadReachPoisFromDOM();

    if (this.reachPois.length > 0) {
      this.fitBounds(this.mapBounds, {
        padding: 60
      });
    }
  }

  loadReachPoisFromDOM() {
    reachPois.forEach((poi) => {
      const formFieldContainer = $(`#reach_poi_${poi.id}`);
      const newPoi = new ReachPoi({
        formFieldContainer,
        'map': this,
        'editable': this.isEditor,
        ...poi,
        'pointType': poi.point_type
      });
      this.reachPois.push(newPoi);
      this.mapBounds.extend(newPoi.lonlat);
    });
  }
}
