import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = process.env.MAPBOX_API_KEY;

// this class just wraps the basic map functionality and
// defaults so that it can be shared across all uses
export class BCWWMap extends mapboxgl.Map {
  constructor(args) {
    const argsPlusDefaults = Object.assign({
      'center': [-127.647621, 53.72669],
      'zoom': 5,
      'style': 'mapbox://styles/mapbox/outdoors-v11',
      'allowFullscreen': true
    }, args);
    super(argsPlusDefaults);

    this.isEditor = argsPlusDefaults.isEditor || false;
    this.mapStyle = 'outdoors-v11';

    this.initializeMapStyleControl();

    if (argsPlusDefaults.allowFullscreen) {
      this.addControl(new mapboxgl.FullscreenControl());
    }
  }

  initializeMapStyleControl() {
    $(this.getContainer()).append(`
    <span id="map_style_control" class="btn btn-info btn-sm">
        Satellite
    </span>
    `);
    $("#map_style_control").on('click', (event) => {
      this.toggleMapStyle();
      $(event.target).text(this.mapStyle.match(/outdoors/) ? 'Satellite' : 'Topo');
    });
  }

  toggleMapStyle() {
    if (this.mapStyle === 'outdoors-v11') {
      this.mapStyle = 'satellite-v9';
    } else {
      this.mapStyle = 'outdoors-v11';
    }
    this.setStyle(`mapbox://styles/mapbox/${this.mapStyle}`);
  }
}
